<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('externalIncentive.circular_list') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
          <b-row>
              <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('globalTrans.message')}}</h4>
                    </template>
                    <template v-slot:body>
                      <b-row>
                        <b-col md="12">
                          <b-overlay :show="loadingData">
                            <div v-if="data">
                              <b-alert show dismissible variant="success" v-if="parseInt(data.status) === 3">
                                {{$t('globalTrans.thanks_invitation')}} <b>&rArr;</b>
                              </b-alert>
                              <b-alert show dismissible variant="success" v-else-if="parseInt(data.status) === 4">
                                  {{$t('globalTrans.cancel_invitation')}} <b>&rArr;</b>
                              </b-alert>
                              <b-alert show dismissible variant="success" v-else>
                                  {{$t('globalTrans.invitation_period_is_over')}} <b>&rArr;</b>
                              </b-alert>
                            </div>
                            <div class="panel-body text-center mt-3" v-else>
                              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                            </div>
                          </b-overlay>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
              </b-col>
          </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
// import { EventBus } from '@/EventBusLayout'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
      return {
        loadingData: false,
        training_type: 0,
        headerText: { text_en: '', text_bn: '' },
        data: null,
        service: {},
        search: {
          status: '',
          hash_code: ''
        }
      }
    },
    computed: {
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        async loadData () {
          if (this.$route.params.status && this.$route.params.hash_code) {
            this.loadingData = true
            const params = {
              status: this.$route.params.status,
              hash_code: this.$route.params.hash_code
            }
            RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/trainer-circular-apply', params).then(response => {
              if (response.success) {
                this.data = response.data
                this.loadingData = false
              } else {
                this.loadingData = false
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
          }
        }
    }
}
</script>
<style scoped>
  .blink_me {
    animation: blinker 2.5s linear infinite;
    color: #3E69F1;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
</style>
